import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
// import DetailAnalysis from "./DetailAnalysis";
import axios from "axios";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumbs from "components/commontools/ToolsBreadcrumbs";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import Loader from "components/loader";
import GuidelineOutput from "components/commontools/GuidelineOutput";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

const GlossaryGenerator = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [TransFromOpen, setTransFromOpen] = useState(false);
  const [TransToOpen, setTransToOpen] = useState(false);
  const [FromSearchInput, setFromSearchInput] = useState("");
  const [ToSearchInput, setToSearchInput] = useState("");
  const [sameLanguageError, setSameLanguageError] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  // const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [guidelineText, setGuidelineText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const TranslateFromRef = useRef(null);
  const debouncedInputText = useDebounce(inputText, 2000);
  const debouncedOutputText = useDebounce(outputText, 2000);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [guidelineCount, setGuidelineCount] = useState(0);
  const [isLgScreen, setIsLgScreen] = useState(false);
  const [shareId, setShareId] = useState("");
  const outputRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsLgScreen(window.innerWidth > 1024);
      const handleResize = () => {
        setIsLgScreen(window.innerWidth > 1024);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (refDiv.current) {
      const height = refDiv.current.offsetHeight;
      setDivHeight(height);
    }
  }, [inputText, outputText, guidelineText, analysisResult]);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectTargetLanguage(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      setTranslateFromInput(language);
      const languageName = Languages?.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }

      // const languageCodeName = response.data?.data?.language_probability?.name;
      // setTranslateFromInput(languageCodeName);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const detectTargetLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const languageTo = response.data?.data?.language_probability?.code;
      setTranslateToInput(languageTo);
      const languageToName = Languages.find(
        (lang) => lang?.code === languageTo
      );

      if (languageToName?.name) {
        setOutputLang(languageTo);
      }
      // const languageCode = response.data?.data?.language_probability?.name;

      // setTranslateToInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError(false);
    setShowOutput(false);
    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "guideline_adherence_checker",
        source_text: inputText,
        translation: outputText,
        user_text: guidelineText,
        // source_language_code: transtalteFromInput,
        // target_language_code: transtalteToInput,
      });

      // setAnalysisResult(response);
      const newShareId = response?.meta?.share_id;
      setShareId(newShareId);
      setShowOutput(true);
      window.history.pushState(
        {},
        "",
        `/tools/guideline-adherence-checker?share-id=${newShareId}`
      );
       window.location.reload();
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {
      setLoading(true);
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        setAnalysisResult(response);
        setShowOutput(true);
        const {
          source_text: sourceText,
          translation_text: translatedText,
          user_text: userTxt,
        } = response.meta;
        setInputText(sourceText);
        setOutputText(translatedText);
        setGuidelineText(userTxt);
        console.log(response, "resposne");
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);

  const findLanguageNameByCode = (code) => {
    const language = Languages.find((lang) => lang.code === code);
    return language ? language.name : null;
  };

  const sourceLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.source_language_code
  );

  const targetLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.target_language_code
  );

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;

  const handleReset = async () => {
    await handleClick();
  };

  const handleClearText = () => {
    setInputText("");
    setOutputText("");
    setGuidelineText("");
    setInputLang("");
    setOutputLang("");
    setTranslateToInput("");
    setTranslateFromInput("");
    setAnalysisResult(null);
    setShowOutput(false);
    setWordCountInput(0);
    setWordCountOutput(0);
    setGuidelineCount(0);
    navigate(`/tools/guideline-adherence-checker/`);
  };
  useEffect(() => {
    if (showOutput && outputRef.current) {
      outputRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [analysisResult, showOutput]);

  return (
    <Layout>
      <SEO
        title="Translation Guideline Adherence Checker"
        description="Check if your translation guidelines were followed by your translator with our Translation Guideline Adherence Checker."
        slug="/tools/guideline-adherence-checker"
      />
      <div className="bg-[#F5F5F5] ">
        <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Translation Guidelines Adherence Checker"
            title="Automated Guideline Checks for Efficient Quality Control"
            description="Enter your source text, translation, and guidelines. The tool checks for any deviations and provides a report with a compliance score and improvement suggestions. Meet your client's expectations and make revisions easier."
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setOutputText={setOutputText}
                  setInputLang={setInputLang}
                  setOutputLang={setOutputLang}
                  setTranslateToInput={setTranslateToInput}
                  setTranslateFromInput={setTranslateFromInput}
                  setAnalysisResult={setAnalysisResult}
                  setShowOutput={setShowOutput}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  handleClearText={handleClearText}
                >
                  {" "}
                  <p className="pb-3 border-b border-[#B0B0B0] text-[#6E6E6E] font-opensans font-bold">
                    Source text
                  </p>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={guidelineCount}
                  setText={setOutputText}
                  setWordCount={setGuidelineCount}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  handleClearText={handleClearText}
                >
                  <p className="pb-3 border-b border-[#B0B0B0] text-[#6E6E6E] font-opensans font-bold">
                    Translated text
                  </p>
                </ToolInput>
              </div>
            </div>
            <ToolInput
              // lang={outputLang}
              text={guidelineText}
              wordCount={wordCountOutput}
              setText={setGuidelineText}
              setWordCount={setWordCountOutput}
              refDiv={refDiv}
              result={analysisResult}
              divHeight={divHeight}
              placeholder="Enter your text here"
              isGuidance={true}
              handleClearText={handleClearText}
              buttontext="Evaluate Text"
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              handleReset={handleReset}
              setDivHeight={setDivHeight}
            >
              <p className="pb-3 border-b border-[#B0B0B0] text-[#6E6E6E] font-opensans font-bold">
                Guidelines
              </p>
            </ToolInput>
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <div ref={outputRef}>
            <GuidelineOutput
              divHeight={divHeight}
              analysisResult={analysisResult}
              showOutput={showOutput}
              isLgScreen={isLgScreen}
              loading={loading}
              error={error}
              refDiv={refDiv}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GlossaryGenerator;
